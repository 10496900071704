@use "/node_modules/@ga/ga-uikit/src/assets/sass/utils/variables" as variables;
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded");

// TEMP fix for tab nav bar indicator
html {
  --mdc-tab-indicator-active-indicator-height: 3px;
}

mat-tab-group.mat-mdc-tab-group .mdc-tab--active .mdc-tab-indicator,
mat-tab-group.mat-mdc-tab-group .mdc-tab-indicator__content--underline,
nav.mat-mdc-tab-nav-bar .mdc-tab--active .mdc-tab-indicator,
nav.mat-mdc-tab-nav-bar .mdc-tab-indicator__content--underline {
  border-bottom: 0 none;
}
// --

// TEMP fix for last row of a table's bottom border
.mdc-data-table__row .mdc-data-table__cell,
.mat-mdc-paginator {
  border-bottom: 1px solid variables.$grey-10 !important;
}

// TEMP fix border on nav tab bar
mat-tab-group.mat-mdc-tab-group .mat-mdc-tab-link-container,
nav.mat-mdc-tab-nav-bar .mat-mdc-tab-link-container {
  border: unset;
  border-bottom: 1px solid variables.$grey-10;
  // z-index: 1000;
}
// --

// TEMP FIX for select value being cut off
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  )
  mat-select.mat-mdc-select
  .mat-mdc-select-trigger {
  line-height: 2.5rem;
}
// --

.invisible {
  min-width: 0 !important;
  width: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  overflow: hidden !important;
}

.mdc-table-fixed-layout {
  white-space: nowrap !important;
  table-layout: fixed !important;
}

.mat-mdc-tooltip.mat-mdc-tooltip-error,
.mat-mdc-tooltip.mat-mdc-tooltip-invalid {
  .mdc-tooltip__surface {
    margin: 0.5rem;
    padding: 0.75rem 1rem;
    color: variables.$black;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: pre-wrap;
  }
}

.mat-mdc-tooltip.mat-mdc-tooltip-error .mdc-tooltip__surface {
  background: variables.$red-wash;
}

.mat-mdc-tooltip.mat-mdc-tooltip-invalid .mdc-tooltip__surface {
  background: variables.$grey-3;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background: #f3f3f3 !important;
  color: #333 !important;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  &.truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
    padding-right: 0.5rem;
  }
}

th .mat-sort-header-container {
  letter-spacing: inherit;

  .mat-sort-header-arrow {
    color: variables.$green-dark;
  }

  .mat-sort-header-content {
    text-align: unset;
  }
}

// .page-content-wrapper {
//   display: flex;
//   flex-direction: column;
//   margin: 0 auto;
//   height: auto;
//   width: 100%;
//   background-color: variables.$white;
//   margin-bottom: 1rem;
// }

// .page-content {
//   width: 912px;
//   max-width: 100%;
//   padding: 0 2rem;
// }

.page-header {
  font-weight: 300;
  margin-bottom: 2rem;

  h1 {
    margin: 1rem 0;
    font-weight: 800;
  }

  p {
    line-height: 1.5;
    max-width: 640px;
  }
}

.page-row-top {
  padding: 1.5rem;
  border-bottom: 1px solid variables.$grey-10;
  font-weight: 800;

  h2 {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 800;
    letter-spacing: 0.15px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    margin-top: 0.5rem;
  }
}

section {
  form {
    padding: 3.75rem 10.5rem;
    font-weight: 300;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
}

// #TechDebt: Need to move these to UI Kit

.bottom-bar {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: variables.$white;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 3rem;
  gap: 1rem;
  z-index: 1000;
  border-top: 1px solid variables.$grey-10;
}

.breadcrumbs {
  font-weight: 300;
  margin: 2rem 0px 1rem 0px;
  span,
  strong {
    margin: 0 0.5rem;
  }
  span:first-of-type {
    margin-left: 0;
  }
}

// File upload
.file-upload {
  padding: 1.5rem;
  margin-bottom: 3.5rem;
  border: 1px dashed variables.$grey-25;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    transition: background-color 0.5s ease;
    background-color: hsla(93, 73%, 55%, 0.11);
  }
}

.file-upload-icon {
  margin-bottom: 0.625rem;
  border: 2px solid variables.$grey-75;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon.mat-icon {
    color: variables.$grey-75;
    height: 2.25rem;
    width: 2.25rem;
    font-size: 2.25rem;
  }
}
// End of file upload

.panel-description {
  padding: 1.5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  width: 702px;
}

// !!
// table:not(.ga-table) {
//   span.mat-select-min-line {
//     font-size: 1rem;
//     font-weight: 300;
//     line-height: 1.5;
//   }
// }

.residency-table,
.taxable-consideration-table {
  .mat-select-arrow-wrapper {
    align-self: flex-start;
  }
}

// side nav

.side-nav-items {
  display: flex;
  flex-direction: column;
  background: variables.$white;
  padding: 0px;
  margin: 0px;
  font-weight: 300;
  height: 100%;
  width: 285px;
  border-radius: 0px;
  overflow-y: scroll;
}

li.mat-tree-node {
  cursor: pointer;
}

mat-tree-node.mat-tree-node {
  flex: 0;
  height: auto;
  min-height: 56px;
}

.label {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  flex: 1;
  height: 3.625rem;
  font-weight: 300;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 1.125rem;
  cursor: pointer;
  border-bottom: 1px solid variables.$grey-10;
  background-color: inherit;
}

.completed {
  color: variables.$green-dark;
}

.incomplete {
  color: variables.$red;
}

.info {
  color: variables.$grey-25;
}

.expanded-node {
  background-color: grey;
}

.highlight {
  font-weight: 700;
  background-color: grey;
}

mat-sidenav.mat-sidenav {
  .side-nav-items::-webkit-scrollbar {
    display: none;
  }
  .back-link {
    display: flex;
    align-items: center;
    background-color: variables.$grey-3;
    border-bottom: 1px solid variables.$grey-10;

    a {
      display: inline-flex;
      flex: 1;
      padding: 0.75rem 1rem;
      text-decoration: none;
      color: variables.$black;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.012rem;
    }

    .mat-icon {
      margin-right: 0.5rem;
    }
  }

  // Nav-items
  mat-tree.mat-tree {
    padding-bottom: 3rem;
    scrollbar-width: auto;
    // mat-tree-node.mat-tree-node:not(.mat-tree-node-label),
    // mat-nested-tree-node.mat-nested-tree-node,
    mat-tree-node.mat-tree-node[aria-level="2"],
    mat-tree-node.mat-tree-node[aria-level="3"],
    mat-tree-node.mat-tree-node[aria-level="4"],
    mat-tree-node.mat-tree-node[aria-level="5"],
    mat-tree-node.mat-tree-node[aria-level="6"] {
      background-color: variables.$white-20;
    }
    .node-collapsed {
      display: none;
    }
    mat-tree-node.mat-tree-node {
      border-bottom: 1px solid variables.$grey-10;

      // &[aria-level="2"] a {
      //   padding-left: 2.5rem;
      // }

      &[aria-level="3"] a {
        padding-left: 3.125rem;
      }

      // &[aria-level="4"] a {
      //   padding-left: 3.75rem;
      // }

      // &[aria-level="5"] a {
      //   padding-left: 5rem;
      // }

      // &[aria-level="6"] a {
      //   padding-left: 6.25rem;
      // }

      a,
      &.mat-tree-node-label {
        display: flex;
        flex: 1;
        align-items: center;
        color: black;
        text-decoration: none;
        font-size: 1rem;
        width: 100%;
        padding: 1rem 0.5rem 1rem 1.125rem;
        font-weight: 300;
      }

      a.active {
        border-right: 4px solid variables.$green;
        font-weight: 700;
      }

      a.disabled {
        pointer-events: none;
        color: variables.$grey-50;
      }

      &.mat-tree-node-label {
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 800;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding-top: 1.625rem;
        padding-bottom: 0.75rem;
      }
    }
    ga-icon {
      transition: transform 0.3s ease-out;
      transform: rotate(180deg);

      &.expanded {
        transform: rotate(0deg);
        transition: transform 0.3s ease-out;
      }
    }
  }
}

// Safari - keep native scrollbar
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    mat-sidenav.mat-sidenav .side-nav-items::-webkit-scrollbar {
      display: unset;
    }
  }
}

.top-nav {
  flex: 0;
  display: flex;
  flex-direction: row;
  background-color: variables.$white;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  border-bottom: 1px solid variables.$grey-10;
}
.menu-button {
  overflow: hidden;
}

// main {
//   // Subtract top navigation tab bar height
//   height: calc(100% - 49px);
// }

mat-sidenav-container.mat-drawer-container {
  flex: 1;
}

// !! What is this?
.mat-expansion-panel-body {
  overflow-y: hidden;
  overflow-x: auto;
}

a[mat-button] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
  height: 3rem;
  min-width: max-content;

  .mat-button-wrapper {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  &.mat-action mat-icon.mat-icon {
    color: variables.$green-darker;
  }

  mat-icon.mat-icon {
    width: auto;
    padding-right: 0.375rem;
  }
}

// ---------------------------------------------->
nav.mat-tab-nav-bar.mat-tab-header.mat-primary {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  a.mat-tab-link {
    color: black;
    font-size: 14px;
  }
  .mat-ink-bar {
    background-color: #8fdc4f;
  }
}

.mat-mdc-button:not(.pending) {
  app-ga-button-spinner {
    display: none;
  }
}

.mat-mdc-button.pending .mdc-button__label {
  visibility: hidden;
  app-ga-button-spinner {
    visibility: visible;
  }
}

// TODO Needs to be added to the UI-Kit
table.mat-mdc-table
  tr.mat-mdc-row
  > td.mat-mdc-cell.highlighted
  mat-select.mat-mdc-select
  .mat-mdc-select-trigger
  .mat-mdc-select-value
  .mat-mdc-select-min-line {
  font-weight: 600;
}

.mdc-dialog__container {
  width: 100%;
}

.ga-card-nav .mat-mdc-tab-link-container {
  border: none !important;
  .mat-mdc-tab-list {
    .mat-mdc-tab-link {
      min-height: 100%;
    }
    display: flex;
  }
}

section.page-content-wrapper {
  background: white;
  margin-bottom: 2rem;
}

.page-content {
  width: 848px;
}

// This card is not an approved component, but will be used until replaced
// Move to separate
.ga-card {
  width: 100%;
  background-color: white;
  border-radius: 0.188rem;
  //overflow: hidden;
  margin-bottom: 1rem;

  font-size: 1rem;
  font-weight: 300;

  .ga-card-head {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    border-bottom: 1px solid #e6e6e6;

    .ga-card-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      & > button.mat-button {
        transform: translateY(-0.25rem);
      }
    }

    h2,
    .ga-card-title {
      font-size: 1.5rem;
      font-weight: 800;
      margin: 0;
      margin-bottom: 1rem;
    }

    p,
    .ga-card-description {
      font-size: 1rem;
      font-weight: 300;
      margin: 0;
      margin-bottom: 1.5rem;
    }
  }

  .ga-card-body {
    padding: 0 1.5rem 1.5rem;
    & > form {
      padding: 0;
      font-weight: 300;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
  }

  .ga-card-nav {
    background-color: white;
    height: 56px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;

    display: flex;
    justify-content: space-between;

    &.ga-card-nav-left,
    .ga-card-nav-left {
      display: flex;
      justify-content: flex-start;
      flex: 1;

      & > .ga-card-nav-icon,
      & > .ga-card-nav-search-bar {
        border-right: 1px solid #e6e6e6;
      }
    }

    &.ga-card-nav-right,
    .ga-card-nav-right {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      & > .ga-card-nav-icon,
      & > .ga-card-nav-search-bar {
        border-left: 1px solid #e6e6e6;
      }
    }

    & > .ga-card-nav-icon {
      cursor: pointer;
      width: 56px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > mat-icon.mat-icon.color-grey {
        color: #8b8b8b;
      }
    }

    & .ga-card-nav-search-bar {
      display: flex;
      align-items: center;
      padding-left: 1.25rem;
      padding-right: 1.25rem;

      input {
        all: unset;
        font-size: 1rem;
        min-width: 17.5rem;
      }

      & > .mat-icon {
        margin-right: 1rem;
      }

      & > input.mat-input-element {
        min-width: 17.5rem;
      }
    }
  }
}

.ga-page {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  app-breadcrumbs {
    margin-bottom: 1rem;
  }

  .ga-page-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    &.ga-page-head-left,
    .ga-page-head-left {
      display: flex;
      justify-content: flex-start;
      flex: 1;
    }

    &.ga-page-head-right,
    .ga-page-head-right {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }

  .ga-page-content {
    max-width: 100%;
    padding: 0 2rem;
    width: 912px;

    & > p,
    & > .ga-page-description {
      font-size: 1rem;
      font-weight: 300;
      margin: 0;
      margin-bottom: 2rem;
      max-width: 37rem;
    }

    &.full-width {
      width: 100%;
      & > p,
      & > .ga-page-description {
        max-width: 54.5rem;
      }
    }

    & > .ga-page-header {
      font-size: 2rem;
      font-weight: 800;
      line-height: 130%;
      margin: 1rem 0;
    }
  }
}

.ga-bottom-bar {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  padding: 1.25rem 3rem;
  gap: 1rem;
  z-index: 1000;
}

.full-width {
  width: 100%;
}
// Temp, remove instances in GAIN first before removing
.bottom-bar {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 3rem;
  gap: 1rem;
  z-index: 1000;
  border-top: 1px solid #e6e6e6;
}

// Temp mat-table styles - using mat-table component for drag-drop to work properly
.dragCursor {
  margin-right: 16px;
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: white !important;
}

.cdk-drag-placeholder {
  background: rgb(227, 227, 227) !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

mat-table.mat-mdc-table {
  mat-row.mat-mdc-row {
    .mat-column-actions,
    .mat-column--actions {
      white-space: nowrap;
      width: 0;
      * {
        margin: 0;
        width: auto;
        height: auto;
        padding: 0 0.2rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell {
    height: 56px;
    mat-icon.mat-icon {
      color: variables.$grey-50;
      cursor: pointer;
    }
    & .mat-mdc-menu-trigger.selected[aria-expanded="true"] mat-icon.mat-icon {
      color: green;
      &:hover {
        color: black;
      }
    }

    &:not(:first-child):has(mat-select) {
      margin-right: 2rem;
    }
  }

  // Headers match GA typography
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell {
    color: variables.$black;
    font-weight: 800;
    letter-spacing: 1.5px;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  //  Table data matches GA typography
  mat-row.mat-mdc-row > td.mat-mdc-cell {
    font-size: 1rem;
    color: variables.$black;
    font-weight: 100;
  }

  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell,
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell {
    &.mat-mdc-table-sticky:first-of-type,
    &.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right {
      box-shadow: -1.2px 10px 0.6rem rgba(0, 0, 0, 0.2);
    }
    &.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right {
      padding-left: 1.5rem;
      width: 0;
      white-space: nowrap;
    }
  }

  // Add some spacing to account for shadow from 1st column - cleaner look
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell,
  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell {
    &.mat-mdc-table-sticky:first-of-type + mat-header-cell,
    &.mat-mdc-table-sticky:first-of-type + mat-cell {
      padding-left: 1rem;
    }
  }
}

// Material table menu dropdown panel, used for more options "..." button, typically used on the last column
.mat-mdc-menu-panel {
  min-width: 0;
  min-height: 0;
  & > .mat-mdc-menu-content {
    padding: 0;
  }
}

// Temp Fix for app-tax-adjustment-page
app-tax-adjustment-page .mdc-floating-label {
  position: static;
  white-space: normal;
}

.mat-date-range-input-wrapper {
  min-height: 2.5rem;
}

app-transactions-search-page {
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-type-mat-date-range-input
    .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: center;
    padding-bottom: 0.2rem;
  }
}

mat-datepicker-toggle.mat-datepicker-toggle {
  width: unset !important;
}

.autocomplete-caret {
  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      margin-right: 1rem;
    }

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7275 0.364258L8.86328 8.22852L0.999024 0.364258' stroke='black' stroke-width='1.1' /%3E%3C/svg%3E");
      width: 1.125rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      bottom: 0;
      align-self: end;
      right: 0;
      transform: translateX(-0.5rem);
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        margin-right: 3rem;
      }
      &::before {
        transform: translateX(-2rem);
        transition: transform 0.5s ease;
      }
    }
  }
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  )
  .mat-mdc-form-field-infix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ).mat-mdc-focused
  .mat-mdc-form-field-infix {
  padding-bottom: 0.2rem;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
  .mat-mdc-form-field-infix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-focused
  .mat-mdc-form-field-infix {
  padding-bottom: 0.25rem !important;
}

// Hiding calendar today's border
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border: none !important;
}

// Mat checkbox color has per design
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #49a303;
}

// scrollbar -----------------------------------------------------------------
/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  // border-radius: 8px;
  border: 0px solid #f8f8f8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 34%);
  border: 0px solid #f8f8f8;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

// SideNav
mat-sidenav.mat-sidenav > div.mat-drawer-inner-container {
  overflow: hidden;
}

// Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: #8b8b8b;
}
// scrollbar -----------------------------------------------------------------

// Remove over shadow
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  visibility: hidden;
}

app-client-reporting-landing-page {
  th.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right {
    z-index: 1 !important;
    right: auto !important;
  }
}

.mat-expansion-panel-padding-min
  .mat-expansion-panel-content
  .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-mdc-paginator-page-size-value {
  font-size: 1rem;
  font-weight: 600;
}
